import React from "react";
import ProfilePhoto from "../../assets/images/Images/profile.jpeg";
import Badge from "../../assets/images/Icons/30yearBage.png";
import AOS from "aos";
import "aos/dist/aos.css";

export default function HomeSection() {
  AOS.init({ duration: 2000 });
  return (
    <div className="home-section">
      <div className="shadow-container">
        <img
          className="badge"
          src={Badge}
          alt="30 years of experience badge"
          data-aos="fade-left"
        />
        <div className="signature-container" data-aos="fade-up">
          <img src={ProfilePhoto} alt="CEO" />
          <div className="profile-wrapper">
            <p className="signature">Muro Mark</p>
            <p className="position">Menadžer Hotelijerstva</p>
          </div>
        </div>
        <div className="home-title" data-aos="fade-left">
          <h1>
            Ugostiteljstvo. <br />
            Profesionalizam. <br />
            Izuzetnost.
          </h1>
        </div>
      </div>
    </div>
  );
}
