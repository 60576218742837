import "../../styles/screens/homeSection.scss";
import React, { useState } from "react";
import MobileNav from "../atoms/mobileNav";
import Logo from "../atoms/logo";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleServiceClick = () => {
    setShowDropdown(false);
  };

  const handleNavLinkClick = (event) => {
    const href = event.currentTarget.getAttribute('href'); // Get the href attribute
    if (href.startsWith('#')) {
      event.preventDefault(); // Prevent the default anchor behavior
      window.location.hash = href; // Set the hash manually
      // No need to manually scroll here, as your useScrollToSection hook should handle it
    }
  };

  return (
    <div id="nav-container">
      <MobileNav />

      <div className="sidebar">
        <Logo />
        {/* <a href="/#home" className="menu-item" onClick={handleNavLinkClick}>
          Naslovna
        </a> */}
        <Link to="/#home" className="menu-item">
          Naslovna
        </Link>
        <div
          className={`menu-item ${showDropdown ? "dropdown" : ""}`}
          onMouseEnter={toggleDropdown}
          onMouseLeave={toggleDropdown}
        >
          Usluge
          {showDropdown && (
            <div className="dropdown-content">
              <Link to="/serviceFirst" onClick={handleServiceClick}>
                Upravljanje prihodima i rashodima
              </Link>
              <Link to="/serviceSecond" onClick={handleServiceClick}>
                Obuka i trening zaposlenih
              </Link>
              <Link to="/serviceThird" onClick={handleServiceClick}>
                Mystery checks
              </Link>
              <Link to="/serviceFourth" onClick={handleServiceClick}>
                Dodela savetodavnog menadžera
              </Link>
            </div>
          )}
        </div>
        <a href="/#about" className="menu-item" onClick={handleNavLinkClick}>
          O nama
        </a>
        <a href="/#contact" className="menu-item" onClick={handleNavLinkClick}>
          Kontakt
        </a>
      </div>
    </div>
  );
};

export default Navbar;
