import "../../styles/screens/services.scss";
import handIcon from "../../assets/images/Icons/handIcon.png";
import handImg from "../../assets/images/Images/handImg.png";
import AOS from "aos";
import "aos/dist/aos.css";

const ServiceSecond = () => {
  AOS.init({ duration: 2000 });
  return (
    <div className="service-second">
      <div className="service-title" data-aos="fade-down">
        <h1 className="title">OBUKA I TRENING ZAPOSLENIH</h1>
        <img className="hands" src={handIcon} alt="Handshake"></img>
      </div>
      <div className="divider"></div>
      <div className="content">
        <ul className="points">
          <li
            className="subtitle-first"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="100"
          >
            Proverene teme za timski trening uključuju:
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="400"
          >
            Savršena komunikacija sa gostom i rešavanje reklamacija.
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="700"
          >
            Aktivna dodatna prodaja gostima u hotelima i restoranima.
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="1000"
          >
            Ključne figure i faktori uticaja u hotelijersvu i ugostiteljsvu.
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="1300"
          >
Društveni mediji: Facebook, Instagram i platforme za ocenjivanje.          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="1600"
          >
            Od gubitka do profitnog centra; nove mogućnosti za prihod kroz
            predlog za nove profitne centre.
          </li>
        </ul>
        <div className="image-container">
          <img src={handImg} alt="Revenue Management" />
        </div>
      </div>
      <div className="subtitle-second">
        <p>
          Kroz obuku o usklađenim temama motivišemo vaše zaposlene da rade
          ciljano kako bi svojim klijentima ponudili neočekivana iskustva u
          usluzi i kvalitetu.
        </p>
      </div>
    </div>
  );
};

export default ServiceSecond;
