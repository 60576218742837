import React from "react";
import data from "../../assets/texts/main.json";
import emailIcon from "../../assets/images/Icons/emailIcon.png";
import phoneIcon from "../../assets/images/Icons/phoneIcon.png";
import "../../styles/screens/contactSection.scss";
import AOS from "aos";
import "aos/dist/aos.css";

export default function ContactSection() {
  AOS.init({ duration: 2000 });
  return (
    <div className="contact-section">
      <div className="contact-container">
        <div className="contact-title" data-aos="fade-down">
          <h2>{data.contact.title}</h2>
        </div>
        <div className="contact-data">
          <div
            className="contact-email"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="100"
          >
            <img src={emailIcon} alt="" />
            <p className="text">{data.contact.email}</p>
          </div>
          <div
            className="contact-phone"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="400"
          >
            <img src={phoneIcon} alt="" />
            <p className="text">{data.contact.phoneNumber}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
