import "../../styles/screens/services.scss";
import managerIcon from "../../assets/images/Icons/managerIcon.png";
import managerImg from "../../assets/images/Images/managerImg.png";
import AOS from "aos";
import "aos/dist/aos.css";

const ServiceFourth = () => {
  AOS.init({ duration: 2000 });
  return (
    <div className="service-fourth">
      <div className="service-title" data-aos="fade-down">
        <h1 className="title">DODELA SAVETODAVNOG MENADŽERA</h1>
        <img className="manager" src={managerIcon} alt="Manager"></img>
      </div>
      <div className="divider"></div>
      <div className="content">
        <ul className="points">
          <li
            className="subtitle-first"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="100"
          >
            Dodelom menadžera za vas objekat dobijate profesionalnu podršku na
            mesečnom nivou.
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="400"
          >
            Kontrola operativnog rada.
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="700"
          >
            Izveštaji o trenutnom stanju i poboljšanju pojedinih segmenata
            hotela.
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="1000"
          >
            Kontrola implementacije dogovorenih mera.
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="1300"
          >
            Redovna obuka zaposlenih.
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="1600"
          >
            Kontrola rashoda i prihoda.
          </li>
        </ul>
        <div className="image-container">
          <img src={managerImg} alt="Manager" />
        </div>
      </div>
    </div>
  );
};

export default ServiceFourth;
