import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "aos/dist/aos.css";
import "../../styles/App.scss";
import MainPage from "../templates/MainPage";
import ServiceFirst from "../organisms/serviceFirst";
import ServiceSecond from "../organisms/serviceSecond";
import ServiceThird from "../organisms/serviceThird";
import ServiceFourth from "../organisms/serviceFourth";
import Layout from "../molecules/layout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route
          path="/serviceFirst"
          element={
            <Layout>
              <ServiceFirst />
            </Layout>
          }
        />
        <Route
          path="/serviceSecond"
          element={
            <Layout>
              <ServiceSecond />
            </Layout>
          }
        />
        <Route
          path="/serviceThird"
          element={
            <Layout>
              <ServiceThird />
            </Layout>
          }
        />
        <Route
          path="/serviceFourth"
          element={
            <Layout>
              <ServiceFourth />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
