import "../../styles/screens/services.scss";
import checkIcon from "../../assets/images/Icons/checkIcon.png";
import checkImg from "../../assets/images/Images/checkImg.png";
import AOS from "aos";
import "aos/dist/aos.css";

const ServiceThird = () => {
  AOS.init({ duration: 2000 });
  return (
    <div className="service-third">
      <div className="service-title" data-aos="fade-down">
        <h1 className="title">SPROVOĐENJE MYSTERY CHECKA</h1>
        <img className="magnifier" src={checkIcon} alt="Magnifier"></img>
      </div>
      <div className="divider"></div>
      <div className="content">
        <ul className="points">
          <li
            className="subtitle-first"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="100"
          >
            Inkognito recenzije profesionalaca:
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="400"
          >
            Naši iskusni stručnjaci za tajnu proveru deluju inkognito kao gosti
            u vašem hotelu i procenjuju svaki aspekt boravka - od rezervacije
            preko prijave usluge restorana i bara do odjave.
          </li>
          <li
            className="subtitle-second"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="700"
          >
            Dragocene povratne informacije i predlozi za optimizaciju:
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="1000"
          >
            Primite detaljne povratne informacije i konkretne predloge za
            optimizaciju kako biste usavršili kvalitet svoje usluge i ponude za
            buduće goste.
          </li>
          <li
            className="subtitle-third"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="1300"
          >
            Obuka i povratne informacije zaposlenih:
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="1600"
          >
            Koristite rezultate misteriozne provere da ponudite ciljanu obuku za
            svoje zaposlene i date im dragocene povratne informacije za
            kontinuirano poboljšanje usluge.
          </li>
        </ul>
        <div className="image-container">
          <img src={checkImg} alt="Mystery Check" />
        </div>
      </div>
    </div>
  );
};

export default ServiceThird;
