import React from "react";
import data from "../../assets/texts/main.json";
import LeftImage from "../../assets/images/Images/uslugeImg1.png";
import RightImage from "../../assets/images/Images/uslugeImg2.png";
import arrow from "../../assets/images/Icons/arrow.png";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

export default function ServiceSection() {
  AOS.init({ duration: 2000 });
  const navigate = useNavigate();

  return (
    <div className="service-section">
      <div className="section-title" data-aos="fade-down">
        <h2>{data.services.title}</h2>
      </div>
      <div className="section-content">
        <div className="image-container left" data-aos="fade-right">
          <img src={LeftImage} alt="glasses" />
        </div>
        <div className="links" data-aos="fade-up">
          {data.services.service.map((service, index) => (
            <div
              key={index}
              className="row"
              onClick={() => navigate(service.path)}
            >
              <p className="service">{service.name}</p>
              <div className="arrow">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          ))}
        </div>
        <div className="image-container right" data-aos="fade-left">
          <img src={RightImage} alt="bar" />
        </div>
      </div>
    </div>
  );
}
