import "../../styles/screens/banner.scss";

const Banner = () => {
  return (
    <div className="banner">
      <p>COPYRIGHT © 2024 IZRADA SAJTA VORTEX-MK | SVA PRAVA ZADRŽANA</p>
    </div>
  );
};

export default Banner;
