import React, { useEffect } from "react";
import Banner from "../atoms/banner";
import Navbar from "../molecules/navbar";
import HomeSection from "../organisms/homeSection";
import ServiceSection from "../organisms/serviceSection";
import AboutSection from "../organisms/aboutSection";
import ContactSection from "../organisms/contactSection";
import { useLocation } from 'react-router-dom';

const useScrollToSection = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    const scrollToElement = () => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          // If the element isn't found, retry after a short delay
          setTimeout(scrollToElement, 100);
        }
      }
    };

    scrollToElement();
  }, [location]);
};

export default function MainPage() {
  useScrollToSection();
  return (
    <div className="main-page">
      <div className="navbar">
        <Navbar />
      </div>
      <section id="home">
        <HomeSection />
      </section>

      <section id="services">
        <ServiceSection />
      </section>

      <section id="about">
        <AboutSection />
      </section>

      <section id="contact">
        <ContactSection />
      </section>

      <section className="banner">
        <Banner />
      </section>
    </div>
  );
};