import React from "react";
import data from "../../assets/texts/main.json";
import BreakLine from "../../assets/images/Icons/decorativeLine.png";
import checkedIcon from "../../assets/images/Icons/checkedIcon.png";
import AOS from "aos";
import "aos/dist/aos.css";

export default function AboutSection() {
  AOS.init({ duration: 2000 });
  return (
    <div className="about-section">
      <div className="about-container">
        <div className="section-title" data-aos="fade-down">
          <h2>{data.aboutUs.title}</h2>
        </div>
        <div className="section-content" data-aos="fade-left">
          <div className="content-text">
            <p className="text">{data.aboutUs.content}</p>
          </div>
          <img src={BreakLine} alt="" />
          <div className="content-list">
            {data.aboutUs.skills.map((e) => (
              <div className="row">
                <img src={checkedIcon} alt="icon"></img>
                <p className="skill">{e}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
