import React from "react";
import Navbar from "./navbar";
import Banner from "../atoms/banner";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <main>{children}</main>
      <Banner />
    </>
  );
};

export default Layout;
