import "../../styles/screens/services.scss";
import coinsIcon from "../../assets/images/Icons/coinsIcon.png";
import coinImg from "../../assets/images/Images/coinImg.png";
import AOS from "aos";
import "aos/dist/aos.css";

const ServiceFirst = () => {
  AOS.init({ duration: 2000 });
  return (
    <div className="service-first">
      <div className="service-title" data-aos="fade-down">
        <h1 className="title">UPRAVLJANJE PRIHODIMA I PRINOSOM</h1>
        <img className="coins" src={coinsIcon} alt="coins"></img>
      </div>
      <div className="divider"></div>
      <div className="content">
        <ul className="points">
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="100"
          >
           Upravljanje prihodima vašeg hotela znači prodaju proizvoda pravom kupcu u optimalno vreme, po najboljoj ceni, kroz pravi kanal distribucije. Cilj procesa je da se maksimizira profit i minimiziraju troskovi.
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="400"
          >
             Korekciju cena na dnevnom nivou i  ponudu cena soba hotela dostizemo  najmanje 10% do 50% postepenog povećanja prihoda od soba.
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="700"
          >
            Posle uvida u kompletne troškove hotela i korekciju baze cene
            proizvoda preuzimamo kompletnu brigu o prodaji i plasmanu proizvoda
            uz garanciju povećanja profita.
          </li>
          <li
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-back"
            data-aos-delay="1000"
          >
            Postepeno vas uvodimo u sistem Revenue Management-a sa krajnjim
            ciljem da profitabilno i samostalno vodite pojedine segmente vašeg
            hotela.
          </li>
        </ul>
        <div className="image-container">
          <img src={coinImg} alt="Revenue Management" />
        </div>
      </div>
    </div>
  );
};

export default ServiceFirst;
